/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/noto-sans-jp"
import "./src/global.scss"

import React, { ReactNode } from "react"
import MediaQuery from "react-responsive"

export const wrapPageElement = (params: { element: ReactNode }): ReactNode => (
  <MediaQuery minWidth={0}>
    <div>
      {/* NOTE: This div tag is needed to watch if  MediaQuery component is ready*/}
      <div className="cypress" data-cy="MediaQueryLoaded" />
      {params.element}
    </div>
  </MediaQuery>
)
